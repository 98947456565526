<template>
  <div class="confirmOrder" v-loading="isloading">
    <!-- 头部 -->
    <!-- <div class="confirmOrder-header">
      <div class="header-content">
        <p>确认订单</p>
      </div>
    </div> -->

    <!-- 主要内容容器 -->
    <div class="content">
      <div class="content_backButton"></div>
      <!-- <div class="content-orderId">
        
      </div> -->
      <div class="back-btn">
        <img src="@/assets/imgs/back-btn.png" alt="" @click="$router.back()" />
        <span class="user-tit">个人中心</span>
      </div>
      <div class="section-address">
        <p class="title">商品结算</p>
        <div class="address-body">
          <div class="address-box-img">
            <img
              :src="
                goodsDetailsList.goodsImg ? goodsDetailsList.goodsImg : defauImg
              "
              alt=""
            />
          </div>
          <ul>
            <li>
              <h2>
                {{
                  goodsDetailsList.goodsName
                    ? goodsDetailsList.goodsName
                    : '网站管理后台内存存储'
                }}
              </h2>
              <p class="phone">
                {{
                  goodsDetailsList.goodsIntroduction
                    ? goodsDetailsList.goodsIntroduction
                    : '内存续费'
                }}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <!-- 选择地址END -->

      <!-- 商品及优惠券 -->
      <div class="section-shipment" v-if="setmeal && setmeal.expansionSize">
        <p class="title">内存年限:</p>
        <p class="shipment">
          {{
            setmeal.expansionSize ? setmeal.expansionSize + 'GB' : ''
          }}&nbsp;{{ setmeal.year ? setmeal.year + '年' : '' }}
        </p>
      </div>

      <!-- 支付方式 -->
      <div class="section-shipment">
        <div class="subItem">
          <div class="itemTitle">支付方式:</div>
          <div class="zhiFuItem">
            <div
              :class="[
                'change-paytype',
                { current: payOrder.payType === 'wxpay' },
              ]"
              @click="selectPayType('wxpay')"
            >
              <div class="change-paytype-icon">
                <img src="../assets/imgs/wxpay.png" class="paytype-icon" />
              </div>
              <div class="change-paytype-info">
                <div class="change-paytype-label">微信支付</div>
              </div>
            </div>
            <div
              :class="[
                'change-paytype',
                { current: payOrder.payType === 'alipay' },
              ]"
              @click="selectPayType('alipay')"
            >
              <div class="change-paytype-icon">
                <img src="../assets/imgs/alipay.png" class="paytype-icon" />
              </div>
              <div class="change-paytype-info">
                <div class="change-paytype-label">支付宝</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 配送方式END -->

      <!-- 结算列表 -->
      <div class="section-count">
        <div class="money-box">
          <ul v-if="goodsAll && !goodsAll.seckill">
            <li>
              <span class="title">原价：</span>
              <span class="value">￥{{ detailsToial.originalPrice }}</span>
            </li>
            <li class="total" style="padding: 30px 0">
              <span class="title">实付金额：</span>
              <span class="value">
                <span class="total-price"
                  ><span class="priceIconTwo">￥</span
                  >{{ detailsToial.payPrice }}</span
                >
              </span>
            </li>
          </ul>
          <ul v-else>
            <li>
              <span class="title">原价：</span>
              <span class="value">￥{{ detailsToial.originalPrice }}</span>
            </li>
            <li class="total" style="padding: 30px 0">
              <span class="title">实付金额：</span>
              <span class="value">
                <span class="total-price"
                  ><span class="priceIconTwo">￥</span
                  >{{ goodsAll ? goodsAll.seckillSellPrice : '' }}</span
                >
              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 结算列表END -->

      <!-- 结算导航 -->
      <div class="section-bar">
        <div class="btn">
          <!-- <el-button class="backButton" @click="goMyOder ('2')">我的订单</el-button>
          <el-button class="backButton" @click="$router.back()" style="background-color: #fff;color: #000;">返回</el-button> -->
          <el-button @click="addOrder" class="backButton">立即支付</el-button>
        </div>
      </div>
      <!-- 结算导航END -->
    </div>
    <!-- 主要内容容器END -->
    <el-dialog
      :visible.sync="codeDialogVisible"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="350px"
      center
      title="使用微信扫码支付"
    >
      <qriously :value="codeUrl" :size="300" />
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import {
  confirmOrderDetail,
  weixinPay,
  updateUserInfo,
  alipayH5,
  alipayPc,
  queryOrderStatus,
} from '@/api/home'
export default {
  name: '',
  data() {
    return {
      // 虚拟数据
      confirmAddress: 1, // 选择的地址id
      isloading: true,
      getTotalPrice: '',
      goodsAll: null,
      defauImg: require('@/assets/imgs/no-image.png'),
      orderId: '',
      orderType: '', //判断是续费单还是正常单
      codeDialogVisible: false, //微信支付二维码弹窗
      headerImg: '',
      detailsToial: {},
      setmeal: {},
      // 地址列表
      goodsDetailsList: [],
      payBtnDisabled: false, //控制立即购买按钮重复提交
      codeUrl: '', //微信支付二维码
      payOrder: {
        productId: '', //商品id
        payType: 'wxpay', //支付方式
        timeType: '',
        timeLimit: '',
      },
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // console.log(to);
        if (to.name == 'ConfirmOrder') {
          if (
            to.query.seckillId &&
            window.location.href.includes('&method=doSomething')
          ) {
            this.getDetails(
              to.query.orderId,
              to.query.productType,
              to.query.seckillId
            )
            // this.timer = setInterval(() => {
            //查询订单是否支付成功
            this.queryOrderStatus(to.query.orderId)
            // }, 3000);
          } else if (window.location.href.includes('&method=doSomething')) {
            this.getDetails(to.query.orderId, to.query.productType)
            // this.timer = setInterval(() => {
            //查询订单是否支付成功
            this.queryOrderStatus(to.query.orderId)
            // }, 3000);
          } else if (to.query.seckillId) {
            this.getDetails(
              to.query.orderId,
              to.query.productType,
              to.query.seckillId
            )
          } else {
            this.getDetails(to.query.orderId, to.query.productType)
          }
          this.orderId = to.query.orderId
        }
        // const type = this.$route.query
        // console.log(type);
      },
    },
  },
  activated() {
    // console.log('mounted this.$route.query', this.$route.query);
    // const { orderId } = this.$route.query
    // this.orderId = orderId;
    // this.orderType = productType
    // this.getDetails(orderId, orderType)
    // this.getSeckillGoods(productID)
    // }
  },
  created() {
    // 如果没有勾选购物车商品直接进入确认订单页面,提示信息并返回购物车
    // if (this.getCheckNum < 1) {
    //   this.notifyError("请勾选商品后再结算");
    //   this.$router.push({ path: "/shoppingCart" });
    // }
    // 判断使用设备
    // console.log('使用设备',this.$device.mobile);
  },
  computed: {
    ...mapGetters(['getUser', 'getNum']),
    userInfo: {
      get() {
        return this.$store.state.user.user
      },
      set(value) {
        this.$store.commit('setUser', value)
      },
    },
  },
  methods: {
    ...mapActions(['setUser']),
    goMyOder(type) {
      this.$router.push({
        name: 'UserCenter',
        query: {
          type: type,
        },
      })
    },
    async getDetails(orderId, productType, seckillId) {
      // console.log(productType);
      const params = {
        orderId: orderId,
        productType: productType,
        seckillId: seckillId,
      }
      const res = await confirmOrderDetail(params)
      console.log('resresresresresres', res)
      if (res.code === 0) {
        this.goodsDetailsList = res.data.data.goods
        this.setmeal = res.data.data?.additionalData
        this.goodsAll = res.data.data
        // console.log(this.goodsAll);
        this.detailsToial = res.data.data.order
        if (this.goodsAll && this.goodsAll.seckillSellPrice > 6000) {
          this.$alert('支付数额过大，建议使用支付宝支付!', '温馨提示', {
            confirmButtonText: '确定',
            callback: () => {
              this.payOrder.payType = 'alipay'
            },
          })
        } else if (this.detailsToial && this.detailsToial.payPrice > 6000) {
          this.$alert('支付数额过大，建议使用支付宝支付!', '温馨提示', {
            confirmButtonText: '确定',
            callback: () => {
              this.payOrder.payType = 'alipay'
            },
          })
        }
        this.isloading = false
      }
    },
    // 判断是否在微信内部浏览器打开
    //   isWechatBrowser() {
    //   const userAgent = navigator.userAgent.toLowerCase();
    //   return /MicroMessenger/i.test(userAgent);
    // },
    // 判断是否在微信内部浏览器打开
    isWX() {
      var wx = window.navigator.userAgent.toLowerCase()
      console.log(wx)
      if (wx.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
    //选择支付方式
    selectPayType(type) {
      // console.log("支付方式：" + type);
      this.payOrder.payType = type
      //this.$router.push({ path: '/order' })
    },
    // 获取url的参数
    getUrlParams() {
      let str = window.location.search
      console.log(str)
      if (str && str.includes('?') && str.includes('=')) {
        let obj = {}
        str = str.slice(1)
        if (str.includes('&')) {
          let arr = str.split('&')
          for (let i = 0; i < arr.length; i++) {
            let one = arr[i].split('=')
            obj[one[0]] = one[1]
          }
        } else {
          let one = str.split('=')
          obj[one[0]] = one[1]
        }
        return obj
      }
    },
    async saveOrder(productType, seckillId) {
      // const  isWechatBrowser = this.isWechatBrowser();
      const requestData = {
        orderId: this.orderId,
        goodsId: this.goodsDetailsList.id, // 根据具体的业务逻辑命名
        payType: '0',
        phoneNumber: this.$store.getters.getUser.mobile,
        sellType: productType,
        seckillId: seckillId,
        // isWechatBrowser==true? 2
        // mobilePay: this.$device.mobile ? 1 : 0
        // mobilePay: this.isWX()==true ? 2 : this.$device.mobile ? 1 : 0
        mobilePay: this.isWX() == true ? 2 : this.$device.mobile ? 1 : 0,
      }
      const res = await weixinPay(requestData)
      if (res.code === 0) {
        //   if (this.$device.mobile && res.paySign) {
        // console.log(res);
        // } else
        if (this.$device.mobile && res.data.paySign && this.isWX() == true) {
          window.wx.config({
            appId: res.data.appId,
            timestamp: res.data.timeStamp,
            paySign: res.data.paySign,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
          })
          window.wx.chooseWXPay({
            appId: res.data.appId,
            timestamp: res.data.timeStamp,
            nonceStr: res.data.nonceStr,
            package: res.data.package,
            signType: res.data.signType,
            paySign: res.data.paySign,
            success: (res) => {
              // 支付成功后的处理
              console.log('支付成功', res)
              this.queryOrderStatus(this.orderId)
            },
            fail: (res) => {
              // 支付失败后的处理
              console.error('支付失败', res)
            },
          })
        } else if (this.$device.mobile) {
          //在跳转支付链接后，拼接微信回跳地址
          // console.log(res.data.codeUrl);
          //
          window.location.href =
            res.data.codeUrl +
            '&redirect_url=' +
            encodeURIComponent(
              window.location.href + '&/UserCenter?type=2&method=doSomething'
            )

          // (function () {
          //   // 解析 URL 参数，获取要执行的方法名
          //   const urlParams = new URLSearchParams(window.location.search);
          //   const methodName = urlParams.get('method');
          //   // 调用特定的方法
          //   if (methodName == 'doSomething') {
          //     console.log(this.orderId);
          //     this.queryOrderStatus(this.orderId);
          //   }
          // })();
        } else {
          this.orderId = res.data.orderId
          this.codeUrl = res.data.codeUrl
          //打开二维码弹窗
          this.codeDialogVisible = true
          //启动定时器
          this.timer = setInterval(() => {
            //查询订单是否支付成功
            this.queryOrderStatus(this.orderId)
          }, 3000)
        }
      }
    },
    async addOrder() {
      const { productType, seckillId } = this.$route.query
      if (!this.$store.getters.getUser) {
        this.$store.dispatch('setShowLogin', true)
        return
      }
      this.payBtnDisabled = true
      if (this.payOrder.payType === 'wxpay') {
        console.log(productType)
        if (productType == 1 || productType == 2 || productType == 3) {
          this.saveOrder(productType, seckillId)
        } else {
          this.saveOrder()
        }
      }
      if (this.payOrder.payType === 'alipay') {
        const params = {
          orderId: this.orderId,
          goodsId: this.goodsDetailsList.id,
          phoneNumber: this.$store.getters.getUser.mobile,
          payType: '1',
          sellType: productType,
          seckillId: seckillId,
        }
        //h5
        if (this.$device.mobile) {
          const res = await alipayH5(params)
          if (res.code == 0) {
            // document.write(res.data.formStr);
            const div = document.createElement('div')
            div.id = 'alipay'
            div.innerHTML = res.data.formStr
            document.body.appendChild(div)
            // document.querySelector('#alipay').children[0].submit
            document.querySelector('#alipay form').submit()
            //取消清除添加的 div 元素
            document.body.removeChild(div)
          } else {
            this.payBtnDisabled = false
          }
        } else {
          //pc
          const res = await alipayPc(params)
          if (res.code == 0) {
            // document.write(res.data.formStr);
            // this.$nextTick(()=>{
            const div = document.createElement('div')
            div.id = 'alipay'
            div.innerHTML = res.data.formStr
            document.body.appendChild(div)
            // document.querySelector('#alipay').children[0].submit
            document.querySelector('#alipay form').submit()
            // })
            // 清除添加的 div 元素
            document.body.removeChild(div)
          } else {
            // this.notifyWarning(res.msg);
            this.payBtnDisabled = false
          }
        }
      }
    },
    //  更新用户信息
    async updateUserInfo() {
      const phone = JSON.parse(this.userInfo.mobile)
      const res = await updateUserInfo(phone)
      if (res.code == 0) {
        const userInfo = res.data.loginUser
        // let user = JSON.stringify(userInfo);
        // localStorage.setItem("user", user);
        this.userInfo = userInfo
      }
    },
    // 查询订单状态
    async queryOrderStatus(orderId) {
      const res = await queryOrderStatus(orderId)
      if (res.code == 0) {
        clearInterval(this.timer)
        this.codeDialogVisible = false
        this.notifySucceed('支付成功')
        this.updateUserInfo()
        setTimeout(() => {
          this.$router.push({
            name: 'UserCenter',
            query: {
              type: '2',
            },
          })
        }, 2500)
      }
    },

    //关闭微信支付二维码对话框时让“确认支付”按钮可用
    closeDialog() {
      // console.log("close.................");
      this.payBtnDisabled = false
      // console.log("清除定时器");
      clearInterval(this.timer)
    },
  },
}
</script>
<style lang="scss" scoped>
.back-btn {
  display: none;
}

.confirmOrder .content .content_backButton {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  // align-items: center;
  margin-top: 20px;
}
.subItem {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  // background-color: red;
  .itemTitle {
    width: 15%;
    font-size: 16px;
    color: #666;
  }
  .itemContent {
    width: 80%;
  }

  ul {
    display: flex;
    align-items: s;
    flex-wrap: wrap;
    width: 100%;
    li {
      font-size: 18px;
      padding: 5px 20px;
      border-radius: 5px;
      border: 1px solid #e7ecf0;
      box-sizing: border-box;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    li:hover {
      cursor: pointer;
      border: 1px solid #fa8919 !important;
      color: #fa8919;
    }
    .selectActive {
      border: 1px solid #fa8919 !important;
      color: #fa8919;
    }
  }
  // 选择支付方式
  .zhiFuItem {
    display: flex;
    /* 选择支付方式 */
    .change-paytype {
      position: relative;
      display: flex;
      align-items: center;
      width: 207px;
      height: 58px;
      margin-right: 10px;
      border: 1px solid #e7ecf0;
      border-radius: 4px;
      cursor: pointer;
    }

    .change-paytype.current {
      border-color: #fa8919;
      color: #fa8919;
    }

    .change-paytype.current:after {
      content: '';
      display: block;
      position: absolute;
      right: -1px;
      bottom: -1px;
      width: 28px;
      height: 28px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAOKADAAQAAAABAAAAOAAAAAANV2hTAAADnUlEQVRoBd3ZzUsUYRwH8O/zzK6llaZkm5mkWdEhN4KEgqKkDhpU9KJpNy/hKch/QK9BdRM7SAQeSnujDgUWXqKgyBBqISKDlswwETXzbZ15eubRGbZ1dp3dmdndmQd255nnZef34Tf7G2UBD7fIta3txKs+FcfA2jwJ1HBq8jwHjMZ5DhiL8xTQCOcZYDycJ4CJcK4HroZzNdAMzrVAszhXApPBuQ6YLM5VwFRwrgGminMF0Aou64FWcVkNtAOXtUC7cFkJtBOXdUC7cVkFdAKXNUCncFkBdBKXcaDTuIwC04HLGDBduIwA04lLOzDduLQCM4FLGzBTuLQAM4lTgZL65lRzGkerWyCduQX41oL9eGvIcOzXJcdxNe2Qqi8LFPv7G4sd+wyBPsNRi4OO4giFVHsdtKpRj1IZ7Nb7sR3bM+gojvohne4E3X1Sd8ivb0Dhr3jNVqCjOH8upLO3QcuPCgtjDEp/G5SBrng2MW4b0BRuTQEwP5kwIMNJvk+60A1aekBMM0WG/LwVLHTfcHn0oC1V1AxOqrsJ6VQnSH4p2NcX0TEk7q8rhu9iL2jJUhFhi/OQn7aAfX6SeN/yrOUiYwanXotsPwxCCEiwiZ9QkQGAJQ4yfxvH9YAUVoh1bGEG8uNmsO+vEu+LmrWUQbM49Xpsbgp0V624NAnsBdlQwjPZFxVKTLdoJ3xND0EKysQEm5uA3NsU93kXs1s/TRmYDE5cbTQEzIyDVh4XpyRQBbI+ADa08nYlgSB8jb18frNYy6ZHsXivHhj9qAdutpMSMGnccjTs1yAwNwm6o0aMkC1BIK8Y7NtLPV5SdhBS/V2Q3I1ijE2EOe48MD6kr0mmkzQwVZwWFBv5wCvpH9CKY2JIFI/cIo7sB6k8AencHZCcPDHHxr4s4aaGte1JH5MCWsVp0bGfA0BkRn+m0ZL9IPxFD10BkXLEMmVkEHJPA7+tx7RtKR1NA+3CaVGy4fcAL/m0/IgYUisl4dVVbUr4DeQHl3imp8S5lTdTQLtxWsBs+B0gR0D5I0RrCq+s8qNmjp/VhiwdVwU6hdOiFv/mRGZBNu2B8qkHyrOrPIURbdryMeGfak7jLEe/ygdw3PTSTW+w0O04lcQIwoZAL+BUIGG0b8Ut6hkc/yb7/P7gfxn0Ck5kj5BO0hoO6UCP4fql8sJWFSqAXsHx75tCCenwlRfWkYbQgsik23Hqo0CtlmpB8fmlLvW2VGFa+wcOncY5YWRXPQAAAABJRU5ErkJggg==)
        no-repeat;
      background-size: 28px 28px;
    }

    .change-paytype-icon {
      margin: 0 11px 0 15px;
    }

    .paytype-icon {
      display: block;
      width: 24px;
      height: 24px;
    }

    .change-paytype-info {
      position: relative;
      flex: 1;
    }
    .change-paytype:hover {
      border-color: #fa8919;
    }
    .change-paytype-label:hover {
      color: #fa8919;
    }
  }
  // 价格
  .price-info {
    width: 80%;
    display: flex;
    align-items: self-end;
    flex-wrap: wrap;
    color: #ff4302;
    margin-top: 20px;
    .sale-price {
      display: flex;
      align-items: self-end;
    }
    .price-icon {
      font-size: 30px;
      font-weight: 400;
      margin-right: 10px;
    }
    .price-val {
      font-size: 45px;
      font-weight: bold;
    }
    .price-old {
      color: #a1a1a1;
      font-size: 25px;
      margin-left: 20px;
    }
  }
}
// 选择支付方式
.zhiFuItem {
  display: flex;
  /* 选择支付方式 */
  .change-paytype {
    position: relative;
    display: flex;
    align-items: center;
    width: 207px;
    height: 58px;
    margin-right: 10px;
    border: 1px solid #e7ecf0;
    border-radius: 4px;
    cursor: pointer;
  }

  .change-paytype.current {
    border-color: #fa8919;
    color: #fa8919;
  }

  .change-paytype.current:after {
    content: '';
    display: block;
    position: absolute;
    right: -1px;
    bottom: -1px;
    width: 28px;
    height: 28px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAOKADAAQAAAABAAAAOAAAAAANV2hTAAADnUlEQVRoBd3ZzUsUYRwH8O/zzK6llaZkm5mkWdEhN4KEgqKkDhpU9KJpNy/hKch/QK9BdRM7SAQeSnujDgUWXqKgyBBqISKDlswwETXzbZ15eubRGbZ1dp3dmdndmQd255nnZef34Tf7G2UBD7fIta3txKs+FcfA2jwJ1HBq8jwHjMZ5DhiL8xTQCOcZYDycJ4CJcK4HroZzNdAMzrVAszhXApPBuQ6YLM5VwFRwrgGminMF0Aou64FWcVkNtAOXtUC7cFkJtBOXdUC7cVkFdAKXNUCncFkBdBKXcaDTuIwC04HLGDBduIwA04lLOzDduLQCM4FLGzBTuLQAM4lTgZL65lRzGkerWyCduQX41oL9eGvIcOzXJcdxNe2Qqi8LFPv7G4sd+wyBPsNRi4OO4giFVHsdtKpRj1IZ7Nb7sR3bM+gojvohne4E3X1Sd8ivb0Dhr3jNVqCjOH8upLO3QcuPCgtjDEp/G5SBrng2MW4b0BRuTQEwP5kwIMNJvk+60A1aekBMM0WG/LwVLHTfcHn0oC1V1AxOqrsJ6VQnSH4p2NcX0TEk7q8rhu9iL2jJUhFhi/OQn7aAfX6SeN/yrOUiYwanXotsPwxCCEiwiZ9QkQGAJQ4yfxvH9YAUVoh1bGEG8uNmsO+vEu+LmrWUQbM49Xpsbgp0V624NAnsBdlQwjPZFxVKTLdoJ3xND0EKysQEm5uA3NsU93kXs1s/TRmYDE5cbTQEzIyDVh4XpyRQBbI+ADa08nYlgSB8jb18frNYy6ZHsXivHhj9qAdutpMSMGnccjTs1yAwNwm6o0aMkC1BIK8Y7NtLPV5SdhBS/V2Q3I1ijE2EOe48MD6kr0mmkzQwVZwWFBv5wCvpH9CKY2JIFI/cIo7sB6k8AencHZCcPDHHxr4s4aaGte1JH5MCWsVp0bGfA0BkRn+m0ZL9IPxFD10BkXLEMmVkEHJPA7+tx7RtKR1NA+3CaVGy4fcAL/m0/IgYUisl4dVVbUr4DeQHl3imp8S5lTdTQLtxWsBs+B0gR0D5I0RrCq+s8qNmjp/VhiwdVwU6hdOiFv/mRGZBNu2B8qkHyrOrPIURbdryMeGfak7jLEe/ygdw3PTSTW+w0O04lcQIwoZAL+BUIGG0b8Ut6hkc/yb7/P7gfxn0Ck5kj5BO0hoO6UCP4fql8sJWFSqAXsHx75tCCenwlRfWkYbQgsik23Hqo0CtlmpB8fmlLvW2VGFa+wcOncY5YWRXPQAAAABJRU5ErkJggg==)
      no-repeat;
    background-size: 28px 28px;
  }

  .change-paytype-icon {
    margin: 0 11px 0 15px;
  }

  .paytype-icon {
    display: block;
    width: 24px;
    height: 24px;
  }

  .change-paytype-info {
    position: relative;
    flex: 1;
  }
  .change-paytype:hover {
    border-color: #fa8919;
  }
  .change-paytype-label:hover {
    color: #fa8919;
  }
}
// @media screen and (min-width: 320px) and (max-width: 420px)
@media screen and (min-width: 320px) and (max-width: 750px) {
  .confirmOrder .content {
    max-width: 100% !important;
  }
  .back-btn {
    display: flex;
    align-items: center;
    margin: 20px;
    img {
      width: 30px;
    }
  }
  .confirmOrder .content .address-body {
    height: 150px !important;
  }
  .confirmOrder .confirmOrder-header .header-content p {
    margin-left: 20px;
  }
  .confirmOrder .content .address-body li {
    // color: #333;
    // height: 9.27083rem;
    padding: 0 !important;
    /* margin-right: 0.88542rem; */
    // margin-bottom: 1.25rem;
    margin-left: 5px !important;
    width: 100% !important;
  }
  .confirmOrder .content .address-body li p {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .confirmOrder .content .address-body .address-box-img img {
    width: 200px !important;
    height: auto;
  }
  .confirmOrder .content .address-body .address-box-img {
    width: 20.5rem !important;
    height: auto;
  }
  // .confirmOrder .content .address-body img {
  //   width: 100% !important;
  //   height: auto;
  // }
  .subItem .itemTitle {
    width: 25%;
  }
  .zhiFuItem {
    width: 80%;
  }
}
.confirmOrder {
  background-color: #f5f5f5;
  padding-bottom: 20px;
  margin-top: 2rem;
}
/* 头部CSS */
.confirmOrder .confirmOrder-header {
  background-color: #fff;
  border-bottom: 2px solid #ff6700;
  margin-bottom: 20px;
}
.confirmOrder .confirmOrder-header .header-content {
  max-width: 1225px;
  margin: 0 auto;
  height: 80px;
  margin-top: 30px;
}
.confirmOrder .confirmOrder-header .header-content p {
  float: left;
  font-size: 28px;
  line-height: 80px;
  color: #424242;
  margin-right: 20px;
}
.confirmOrder .confirmOrder-header .header-content p i {
  font-size: 45px;
  color: #ff6700;
  line-height: 80px;
}
/* 头部CSS END */

/* 主要内容容器CSS */
.confirmOrder .content {
  max-width: 85%;
  margin: 0 auto;
  // padding: 48px 0 0;
  background-color: #fff;
}
.confirmOrder .content .backButton {
  // margin: 0.5rem 1.5rem;
  background: #fa8919;
  color: #fff;
  width: 158px;
  height: 3.125rem;
  font-size: 30px;
}
.confirmOrder .content .content-orderId {
  // margin: 0 auto;
  margin: 10 2.5rem;
  padding-bottom: 10px;
}

/* 选择地址CSS */
.confirmOrder .content .section-address {
  margin: 20px 48px;
  overflow: hidden;
}
.confirmOrder .content .section-address .title {
  color: #333;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 20px;
}
.confirmOrder .content .address-body {
  display: flex;
  flex-direction: row;
  // height: 250px;
  height: auto;
  cursor: pointer;
}
.confirmOrder .content .address-body .address-box-img {
  width: 720px;
  height: 520px;
}
.confirmOrder .content .address-body .address-box-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.confirmOrder .content .address-body ul {
  width: 50%;
}
.confirmOrder .content .address-body li {
  // float: left;
  color: #333;
  // width: 100%;
  height: 178px;
  // border: 1px solid #e0e0e0;
  padding: 15px 24px 0;
  margin-right: 17px;
  margin-bottom: 24px;
}
.confirmOrder .content .address-body .in-section {
  border: 1px solid #ff6700;
}
.confirmOrder .content .address-body li h2 {
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  margin-bottom: 10px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.confirmOrder .content .address-body li p {
  font-size: 14px;
  color: #757575;
}
.confirmOrder .content .address-body li .address {
  padding: 10px 0;
  max-width: 180px;
  max-height: 88px;
  line-height: 22px;
  overflow: hidden;
}
.confirmOrder .content .address-body .add-address {
  text-align: center;
  line-height: 30px;
}
.confirmOrder .content .address-body .add-address i {
  font-size: 30px;
  padding-top: 50px;
  text-align: center;
}
/* 选择地址CSS END */

/* 商品及优惠券CSS */
.confirmOrder .content .section-goods {
  margin: 0 48px;
}
.confirmOrder .content .section-goods p.title {
  color: #333;
  font-size: 18px;
  line-height: 40px;
}
.confirmOrder .content .section-goods .goods-list {
  padding: 5px 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.confirmOrder .content .section-goods .goods-list li {
  padding: 10px 0;
  color: #424242;
  overflow: hidden;
}
.confirmOrder .content .section-goods .goods-list li img {
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.confirmOrder .content .section-goods .goods-list li .pro-name {
  float: left;
  width: 650px;
  line-height: 30px;
}
.confirmOrder .content .section-goods .goods-list li .pro-price {
  float: left;
  width: 150px;
  text-align: center;
  line-height: 30px;
}
.confirmOrder .content .section-goods .goods-list li .pro-status {
  float: left;
  width: 99px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.confirmOrder .content .section-goods .goods-list li .pro-total {
  float: left;
  width: 190px;
  text-align: center;
  color: #ff6700;
  line-height: 30px;
}
/* 商品及优惠券CSS END */

/* 配送方式CSS */
.confirmOrder .content .section-shipment {
  margin: 0 48px;
  padding: 25px 0;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
}
.confirmOrder .content .section-shipment .title {
  float: left;
  width: 150px;
  color: #333;
  font-size: 18px;
  line-height: 38px;
}
.confirmOrder .content .section-shipment .shipment {
  float: left;
  line-height: 38px;
  font-size: 20px;
  color: #ff6700;
}
/* 配送方式CSS END */

/* 发票CSS */
.confirmOrder .content .section-invoice {
  margin: 0 48px;
  padding: 25px 0;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
}
.confirmOrder .content .section-invoice .title {
  float: left;
  width: 150px;
  color: #333;
  font-size: 18px;
  line-height: 38px;
}
.confirmOrder .content .section-invoice .invoice {
  float: left;
  line-height: 38px;
  font-size: 14px;
  margin-right: 20px;
  color: #ff6700;
}
/* 发票CSS END */

/* 结算列表CSS */
.confirmOrder .content .section-count {
  margin: 0 48px;
  padding: 30px 0;
  overflow: hidden;
}
.confirmOrder .content .section-count .money-box {
  float: right;
  text-align: right;
}
.confirmOrder .content .section-count .money-box .title {
  float: left;
  width: 126px;
  height: 30px;
  display: block;
  line-height: 30px;
  color: #757575;
}
.confirmOrder .content .section-count .money-box .value {
  float: left;
  min-width: 105px;
  height: 50px;
  font-size: 1.30208rem;
  display: block;
  line-height: 30px;
  color: #a1a1a1;
}
.confirmOrder .content .section-count .money-box .total .title {
  padding-top: 15px;
}
.confirmOrder .content .section-count .money-box .total .value {
  padding-top: 10px;
}
.confirmOrder .content .section-count .money-box .total-price {
  font-size: 2.34375rem;
  color: #ff4302;
  font-weight: 700;
}
.total-price .priceIconOne {
  font-size: 1.30208rem;
  color: #a1a1a1;
}
.total-price .priceIconTwo {
  font-size: 1.5625rem;
  font-weight: normal;
}
/* 结算列表CSS END */

/* 结算导航CSS */
.confirmOrder .content .section-bar {
  padding: 40px 48px;
  border-top: 2px solid #f5f5f5;
  overflow: hidden;
}
.confirmOrder .content .section-bar .btn {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  justify-content: flex-end;
}
.confirmOrder .content .section-bar .btn .btn-base {
  float: left;
  margin-left: 30px;
  width: 158px;
  height: 38px;
  border: 1px solid #b0b0b0;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
}
.confirmOrder .content .section-bar .btn .btn-return {
  color: rgba(0, 0, 0, 0.27);
  border-color: rgba(0, 0, 0, 0.27);
}
.confirmOrder .content .section-bar .btn .btn-primary {
  background: #ff6700;
  border-color: #ff6700;
  color: #fff;
}
/* 结算导航CSS */

/* 主要内容容器CSS END */
</style>
